/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'EinaBold';
    src: url('./assets/fonts/Eina02-Bold.ttf') format('trueType');
    font-style: normal;
    font-weight: normal;
  }
  @font-face {
    font-family: 'EinaSemiBold';
    src: url('./assets/fonts/Eina02-SemiBold.ttf') format('trueType');
    font-style: normal;
    font-weight: normal;
  }
  @font-face {
    font-family: 'EinaRegular';
    src: url('./assets/fonts/Eina02-Regular.ttf') format('trueType');
    font-style: normal;
    font-weight: normal;
  }
  @font-face {
    font-family: 'EinaO1Regular';
    src: url('./assets/fonts/Eina01-Regular.ttf') format('trueType');
    font-style: normal;
    font-weight: normal;
  }
  @font-face {
    font-family: 'EinaO1Semibold';
    src: url('./assets/fonts/Eina01-SemiBold.ttf') format('trueType');
    font-style: normal;
    font-weight: normal;
  }